<ngx-spinner></ngx-spinner>
<div class="row ml-0 mr-0">
    <div #largePanel class="col-lg-3 pl-0 pr-0">
        <div class="container-lg d-inline d-lg-flex flex-column min-vh-100 p-0">
            <div class="row ml-0 mr-0 flex-fill flex-column">
                <div class="col-12 p-4 section-search">
                    <div class="container p-0">
                        <div class="row">
                            <div class="col-12 mx-auto mx-lg-0">
                                <drooltool-stylized-dropdown [heading]="'Choose from one of the metrics below'"
                                    [options]="metrics" [(selectedOption)]="selectedMetric" [showInstructions]="true"
                                    (showInstructionsTriggered)="showInstructions = true"
                                    (selectedOptionChange)="clearErrorsAndDisplayNewMetric()">
                                </drooltool-stylized-dropdown>
                                <drooltool-stylized-dropdown [heading]="'Choose a watershed'" [options]="watershedNames"
                                    [(selectedOption)]="selectedWatershed"
                                    (selectedOptionChange)="getNewWatershedMask()"></drooltool-stylized-dropdown>
                                <drooltool-stylized-dropdown [heading]="'Choose a year'" [options]="getYears()"
                                    [(selectedOption)]="selectedMetricYear"
                                    (selectedOptionChange)="getSelectedMetricYearAvailableMonthsThenDisplayMetric()">
                                </drooltool-stylized-dropdown>
                                <ng5-slider style="max-width:353px" [(value)]="selectedMetricMonth"
                                    [options]="ng5SliderOptions" (userChange)="getAppropriateMetricsForDateChange()">
                                </ng5-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="errorActive" class="col-12 p-lg-4 flex-fill">
                    <div class="row">
                        <div class="col-12 text-right">
                            <span><a style="font-size:1.5rem" class="search-close" (click)="returnToDefault()"><i
                                        class="fas fa-close"></i></a></span>
                        </div>
                    </div>
                    <div class="row section-search-not-found">
                        <div class="col-2 m-auto p-0 text-center">
                            <span><i class="fas fa-exclamation fa-3x"></i></span>
                        </div>
                        <div class="col-10 my-auto">
                            <h3 class="mb-0">
                                {{errorMessage}}
                            </h3>
                        </div>
                    </div>
                    <br />
                    <div class="row section-search-not-found mb-1">
                        <div class="col-2 text-center m-auto p-0 text-center" [innerHTML]="errorSpecificIcon">
                        </div>
                        <div class="col-10 my-auto">
                            <h3 class="mb-0">
                                {{errorCallToAction}}

                            </h3>
                        </div>
                    </div>
                </div>
                <div *ngIf="!errorActive && selectedMetric == WatershedExplorerMetric.NoMetric"
                    class="col-12 section-stats d-none d-lg-flex p-lg-4 flex-fill">
                    <div class="h-100 mt-auto mb-auto">
                        <h3>
                            Learn about how your watershed is conserving water and protecting our streams and beaches
                        </h3>
                        <h3 class="text-center">
                            <span><i class="fas fa-tint fa-2x"></i></span>
                        </h3>
                        <h3>
                            Click the exposed areas of the map to explore different watershed metrics
                        </h3>
                    </div>
                </div>
                <div *ngIf="!errorActive && selectedMetric != WatershedExplorerMetric.NoMetric"
                    class="col-12 section-stats d-none d-lg-flex p-lg-4 flex-fill">
                    <div class="h-100 w-100">
                        <p class="b3 text-left">Displaying data for {{months[selectedMetricMonth - 1]}}
                            {{selectedMetricYear}}</p>
                        <ng-container *ngFor="let legendColor of selectedMetric.legendColors; index as i">
                            <div class="row mb-1">
                                <div class="col-2 mt-auto mb-auto">
                                    <div class="metric-color-swatch" [style.background]="legendColor"></div>
                                </div>
                                <div class="col-10">
                                    <p class="b3 mt-auto mb-auto text-left">
                                        {{selectedMetric.legendValues[i]}}
                                    </p>
                                </div>
                            </div>
                        </ng-container>
                        <p class="b3 text-left">*Measured in {{selectedMetric.metricUnits}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-9 pl-0 pr-0">
        <div #mapDiv id={{mapID}} class="location-card" [style.min-height]="mapHeight" style="height:100%">
            <div (click)="$event.stopPropagation();">
                <ngb-toast #instructionsToast class="instructions-toast" *ngIf="showInstructions"
                    header="Get started with the Watershed Map!" (click)="$event.stopPropagation()"
                    (hide)="hideInstructionsToast($event)" [autohide]="false">
                    Explore drool creation and outdoor rebate participation metrics over time by toggling the controls in the panel. Click on any of the highlighted neighborhoods to learn more about them!
                </ngb-toast>
            </div>
            <div id="buttonDiv" *ngIf="areMetricsCollapsed && !errorActive" [class]="layerControlOpen ? 'shifted' : ''">
                <button class="btn btn-drooltool d-lg-none mb-1 w-100" [attr.aria-expanded]="!areMetricsCollapsed"
                    aria-expanded="false" aria-controls="collapsePanel" aria-label="Toggle navigation"
                    (click)="showMetrics($event)"><span>Metric Info</span></button>
                <br class="d-lg-none" />
                <button *ngIf="searchActive" (click)="displayTrace($event, true)"
                    class="btn btn-drooltool mb-1 w-100" [disabled]="currentlyTracing">
                    <span *ngIf="currentlyTracing" class="fa fa-spinner loading-spinner mr-2"></span>
                    <span>What drains to here?</span>
                </button>
                <br/>
                <button *ngIf="searchActive" (click)="displayTrace($event, false)"
                    class="btn btn-drooltool mb-1 w-100" [disabled]="currentlyTracing">
                    <span *ngIf="currentlyTracing" class="fa fa-spinner loading-spinner mr-2"></span>
                    <span>Where does water flow?</span>
                </button>
            </div>
            <div class="mobile-stats-containing-div" *ngIf="!errorActive">
                <div *ngIf="selectedMetric != WatershedExplorerMetric.NoMetric" id="collapsePanel"
                    class="section-stats mt-2 collapse mobile-stats metrics-collapse d-lg-none"
                    [ngbCollapse]="areMetricsCollapsed" (click)="$event.stopPropagation();">
                    <div class="col-12 section-stats d-lg-none mt-1 metrics-panel-transparency">
                        <div class="h-100 w-100 m-auto">
                            <p class="b3">Displaying data for <br /> {{months[selectedMetricMonth - 1]}}
                                {{selectedMetricYear}}
                            </p>
                            <ng-container *ngFor="let legendColor of selectedMetric.legendColors; index as i">
                                <div class="row mb-1">
                                    <div class="col-12 mt-auto mb-auto">
                                        <div class="metric-color-swatch ml-auto d-inline-block align-middle"
                                            [style.background]="legendColor"></div>
                                        <span class="b3 ml-1 text-left" style="position:absolute; top:3px">
                                            {{selectedMetric.legendValues[i]}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <p class="b3">*Measured in <br />{{selectedMetric.metricUnits}}</p>
                        </div>
                    </div>
                    <button class="btn btn-drooltool metrics-button-panel-expanded"
                        [attr.aria-expanded]="!areMetricsCollapsed" aria-expanded="false" aria-controls="collapsePanel"
                        aria-label="Toggle navigation" (click)="showMetrics($event)"><span><a class="search-close"><i
                                    class="fas fa-close"></i></a></span></button>

                </div>
            </div>
        </div>
    </div>
</div>