//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[CustomRichTextType]

export enum CustomRichTextTypeEnum {
  About = 1,
  TakeAction = 2,
  Help = 3,
  ProvideFeedback = 4
}
