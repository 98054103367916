<div class="row">
    <div class="col-12 text-center text-lg-left">
        <h4 class="mb-0" style="color:white">{{heading}}
            <span class="search-open" *ngIf="showInstructions"><a (click)="showInstructionsTriggered.emit(null)"><i
                class="far fa-question-circle"></i></a></span>
        </h4>
    </div>
    <div class="col-md-12 text-center text-lg-left">
      <div class="dropdown btn-group">
        <br/>
        <a href="#" style="color:white" class="nav-link dropdown-toggle font-weight-bold" type="button" data-toggle="dropdown">{{selectedOption}}
          <span class="caret"></span>
        </a>
  
        <ul class="dropdown-menu">
            <ng-template ngFor let-option [ngForOf]="options">
                <li><a class="dropdown-item" (click)="selectedOption = option">
                    {{option}}
                </a></li>
            </ng-template>
        </ul>
  
      </div>
    </div>
  </div>