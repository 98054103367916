/**
 * DroolTool.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DroolPerLandscapedAcreChartDto } from '../model/drool-per-landscaped-acre-chart-dto';
import { MetricDateDto } from '../model/metric-date-dto';
import { NeighborhoodMetricAvailableDatesDto } from '../model/neighborhood-metric-available-dates-dto';
import { NeighborhoodMetricDto } from '../model/neighborhood-metric-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class NeighborhoodService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetDefaultMetricDisplayDateGet(observe?: 'body', reportProgress?: boolean): Observable<MetricDateDto>;
    public neighborhoodGetDefaultMetricDisplayDateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MetricDateDto>>;
    public neighborhoodGetDefaultMetricDisplayDateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MetricDateDto>>;
    public neighborhoodGetDefaultMetricDisplayDateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MetricDateDto>(`${this.basePath}/neighborhood/get-default-metric-display-date`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param neighborhoodID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetDroolPerLandscapedAcreChartNeighborhoodIDGet(neighborhoodID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DroolPerLandscapedAcreChartDto>>;
    public neighborhoodGetDroolPerLandscapedAcreChartNeighborhoodIDGet(neighborhoodID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DroolPerLandscapedAcreChartDto>>>;
    public neighborhoodGetDroolPerLandscapedAcreChartNeighborhoodIDGet(neighborhoodID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DroolPerLandscapedAcreChartDto>>>;
    public neighborhoodGetDroolPerLandscapedAcreChartNeighborhoodIDGet(neighborhoodID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (neighborhoodID === null || neighborhoodID === undefined) {
            throw new Error('Required parameter neighborhoodID was null or undefined when calling neighborhoodGetDroolPerLandscapedAcreChartNeighborhoodIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DroolPerLandscapedAcreChartDto>>(`${this.basePath}/neighborhood/get-drool-per-landscaped-acre-chart/${encodeURIComponent(String(neighborhoodID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetMetricTimelineGet(observe?: 'body', reportProgress?: boolean): Observable<Array<NeighborhoodMetricAvailableDatesDto>>;
    public neighborhoodGetMetricTimelineGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NeighborhoodMetricAvailableDatesDto>>>;
    public neighborhoodGetMetricTimelineGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NeighborhoodMetricAvailableDatesDto>>>;
    public neighborhoodGetMetricTimelineGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NeighborhoodMetricAvailableDatesDto>>(`${this.basePath}/neighborhood/get-metric-timeline`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetMostRecentMetricGet(observe?: 'body', reportProgress?: boolean): Observable<NeighborhoodMetricDto>;
    public neighborhoodGetMostRecentMetricGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NeighborhoodMetricDto>>;
    public neighborhoodGetMostRecentMetricGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NeighborhoodMetricDto>>;
    public neighborhoodGetMostRecentMetricGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NeighborhoodMetricDto>(`${this.basePath}/neighborhood/get-most-recent-metric`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetNeighborhoodsWithMetricsIdsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public neighborhoodGetNeighborhoodsWithMetricsIdsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public neighborhoodGetNeighborhoodsWithMetricsIdsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public neighborhoodGetNeighborhoodsWithMetricsIdsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/neighborhood/get-neighborhoods-with-metrics-ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetServicedNeighborhoodIdsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public neighborhoodGetServicedNeighborhoodIdsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public neighborhoodGetServicedNeighborhoodIdsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public neighborhoodGetServicedNeighborhoodIdsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<number>>(`${this.basePath}/neighborhood/get-serviced-neighborhood-ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodGetServicedNeighborhoodsWatershedNamesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public neighborhoodGetServicedNeighborhoodsWatershedNamesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public neighborhoodGetServicedNeighborhoodsWatershedNamesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public neighborhoodGetServicedNeighborhoodsWatershedNamesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/neighborhood/get-serviced-neighborhoods-watershed-names`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param neighborhoodID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodNeighborhoodIDGetDownstreamBackboneTraceGet(neighborhoodID: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public neighborhoodNeighborhoodIDGetDownstreamBackboneTraceGet(neighborhoodID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public neighborhoodNeighborhoodIDGetDownstreamBackboneTraceGet(neighborhoodID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public neighborhoodNeighborhoodIDGetDownstreamBackboneTraceGet(neighborhoodID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (neighborhoodID === null || neighborhoodID === undefined) {
            throw new Error('Required parameter neighborhoodID was null or undefined when calling neighborhoodNeighborhoodIDGetDownstreamBackboneTraceGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/neighborhood/${encodeURIComponent(String(neighborhoodID))}/get-downstream-backbone-trace`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param neighborhoodID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodNeighborhoodIDGetStormshedGet(neighborhoodID: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public neighborhoodNeighborhoodIDGetStormshedGet(neighborhoodID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public neighborhoodNeighborhoodIDGetStormshedGet(neighborhoodID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public neighborhoodNeighborhoodIDGetStormshedGet(neighborhoodID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (neighborhoodID === null || neighborhoodID === undefined) {
            throw new Error('Required parameter neighborhoodID was null or undefined when calling neighborhoodNeighborhoodIDGetStormshedGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/neighborhood/${encodeURIComponent(String(neighborhoodID))}/get-stormshed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param neighborhoodID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodNeighborhoodIDGetUpstreamBackboneTraceGet(neighborhoodID: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public neighborhoodNeighborhoodIDGetUpstreamBackboneTraceGet(neighborhoodID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public neighborhoodNeighborhoodIDGetUpstreamBackboneTraceGet(neighborhoodID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public neighborhoodNeighborhoodIDGetUpstreamBackboneTraceGet(neighborhoodID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (neighborhoodID === null || neighborhoodID === undefined) {
            throw new Error('Required parameter neighborhoodID was null or undefined when calling neighborhoodNeighborhoodIDGetUpstreamBackboneTraceGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/neighborhood/${encodeURIComponent(String(neighborhoodID))}/get-upstream-backbone-trace`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param oCSurveyNeighborhoodID 
     * @param metricEndYear 
     * @param metricEndMonth 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet(oCSurveyNeighborhoodID: number, metricEndYear: number, metricEndMonth: number, observe?: 'body', reportProgress?: boolean): Observable<Array<NeighborhoodMetricDto>>;
    public neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet(oCSurveyNeighborhoodID: number, metricEndYear: number, metricEndMonth: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NeighborhoodMetricDto>>>;
    public neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet(oCSurveyNeighborhoodID: number, metricEndYear: number, metricEndMonth: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NeighborhoodMetricDto>>>;
    public neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet(oCSurveyNeighborhoodID: number, metricEndYear: number, metricEndMonth: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (oCSurveyNeighborhoodID === null || oCSurveyNeighborhoodID === undefined) {
            throw new Error('Required parameter oCSurveyNeighborhoodID was null or undefined when calling neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet.');
        }

        if (metricEndYear === null || metricEndYear === undefined) {
            throw new Error('Required parameter metricEndYear was null or undefined when calling neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet.');
        }

        if (metricEndMonth === null || metricEndMonth === undefined) {
            throw new Error('Required parameter metricEndMonth was null or undefined when calling neighborhoodOCSurveyNeighborhoodIDMetricEndYearMetricEndMonthGetMetricsForYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NeighborhoodMetricDto>>(`${this.basePath}/neighborhood/${encodeURIComponent(String(oCSurveyNeighborhoodID))}/${encodeURIComponent(String(metricEndYear))}/${encodeURIComponent(String(metricEndMonth))}/get-metrics-for-year`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param oCSurveyNeighborhoodID 
     * @param metricYear 
     * @param metricMonth 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet(oCSurveyNeighborhoodID: number, metricYear: number, metricMonth: number, observe?: 'body', reportProgress?: boolean): Observable<NeighborhoodMetricDto>;
    public neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet(oCSurveyNeighborhoodID: number, metricYear: number, metricMonth: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NeighborhoodMetricDto>>;
    public neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet(oCSurveyNeighborhoodID: number, metricYear: number, metricMonth: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NeighborhoodMetricDto>>;
    public neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet(oCSurveyNeighborhoodID: number, metricYear: number, metricMonth: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (oCSurveyNeighborhoodID === null || oCSurveyNeighborhoodID === undefined) {
            throw new Error('Required parameter oCSurveyNeighborhoodID was null or undefined when calling neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet.');
        }

        if (metricYear === null || metricYear === undefined) {
            throw new Error('Required parameter metricYear was null or undefined when calling neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet.');
        }

        if (metricMonth === null || metricMonth === undefined) {
            throw new Error('Required parameter metricMonth was null or undefined when calling neighborhoodOCSurveyNeighborhoodIDMetricYearMetricMonthGetMetricsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NeighborhoodMetricDto>(`${this.basePath}/neighborhood/${encodeURIComponent(String(oCSurveyNeighborhoodID))}/${encodeURIComponent(String(metricYear))}/${encodeURIComponent(String(metricMonth))}/get-metrics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
