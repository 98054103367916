<div class="container pt-2">

    <app-alert-display></app-alert-display>

    <div class="row mb-2">
        <div class="col">
            <h1>Provide Feedback</h1>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <drooltool-custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></drooltool-custom-rich-text>
        </div>
    </div>
</div>
<div class="container">
    <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name">Name</label>
            <input formControlName="name" id="name" type="text" class="form-control">
        </div>
        <div class="form-group">
            <label for="name">Email</label>
            <input formControlName="email" id="email" type="text" class="form-control">
            <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group">
            <label for="phone">Phone Number</label>
            <input formControlName="phone" id="phone" type="text" class="form-control">
            <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger">
                <div *ngIf="f.phone.errors.maxlength">Phone number may not exceed 20 characters</div>
            </div>
        </div>
        <div class="form-group">
            <label for="content" class="required">Feedback</label>
            <textarea class="form-control" name="content" id="content" formControlName="content" rows="6"></textarea>
            <div *ngIf="f.content.touched && f.content.invalid" class="alert alert-danger">
                <div *ngIf="f.content.errors.required">Feedback is required.</div>
            </div>
        </div>
        <i class="fas fa-bolt"></i> Required Field
        <br/>
        <br/>
        <small>This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
        <br/>
        <br/>
        <button type="submit" class="btn btn-drooltool" [disabled]="isPerformingAction">
            <span *ngIf="isPerformingAction" class="fa fa-spinner loading-spinner"></span>
            Submit</button> &nbsp;
        <button (click)="redirectToHome()" type="button" class="btn btn-secondary" [disabled]="isPerformingAction">Cancel</button>
    </form>
</div>