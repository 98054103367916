<div class="container pt-2">
    <div class="row mb-2">
        <div class="col">
            <h1>Help</h1>

            <div class="mt-2">
                <drooltool-custom-rich-text [customRichTextTypeID]="richTextTypeID"></drooltool-custom-rich-text>
            </div>
        </div>
    </div>
</div>