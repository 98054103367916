<div class="row ml-0 mr-0">
    <div class="col-lg-3 pl-0 pr-0">
        <div class="container-lg d-inline d-lg-flex flex-column min-vh-100 p-0">
            <div class="row ml-0 mr-0 flex-fill flex-column">
                <div class="col-12 p-4 section-search">
                    <div class="container p-0">
                        <div class="row">
                            <div class="col-12 mx-auto mx-lg-0">
                                <h3 style="color:white">How is my neighborhood doing? <span class="d-lg-none"><a
                                            class="search-close" (click)="showInstructions = true"><i
                                                class="far fa-question-circle"></i></a></span></h3>
                                <div class="input-group mx-auto mx-lg-0" style="max-width:353px">
                                    <input #searchText type="text" class="form-control b4 text-left"
                                        placeholder="Enter address or zip code"
                                        (keyUp.enter)="makeNominatimRequest(searchText)">
                                    <div class="input-group-append">
                                        <button class="btn btn-drooltool search" type="button"
                                            (click)="makeNominatimRequest(searchText)">
                                            <i class="fas fa-search" style="color:white"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showInstructions" class="col-12 d-lg-none">
                    <div class="row">
                        <div class="col-12 text-right">
                            <span><a class="search-close" (click)="showInstructions = false"><i
                                        class="fas fa-close"></i></a></span>
                        </div>
                    </div>
                    <div class="row section-stats">
                        <div class="col-12 text-center mb-3">
                            <h3 class="mb-0 pl-2 pr-2">
                                Click the exposed areas of the map to explore different neighborhood metrics or
                                enter your address in the search bar for your own detailed urban drool snapshot
                            </h3>
                        </div>
                    </div>
                </div>
                <div *ngIf="searchActive" class="col-12 align-middle text-center p-2 section-active-search">
                    <p class="b4 d-inline" style="font-weight:800">{{searchAddress || "My Selected Neighborhood"}}<span
                            style="margin-left:4px"><a class="search-close" (click)="returnToDefault()"><i
                                    class="fas fa-close"></i></a></span></p>
                </div>
                <div *ngIf="activeSearchNotFound" class="col-12 p-lg-4 flex-fill">
                    <div class="row">
                        <div class="col-12 text-right">
                            <span><a class="search-close" (click)="returnToDefault()"><i
                                        class="fas fa-close"></i></a></span>
                        </div>
                    </div>
                    <div class="row section-search-not-found">
                        <div class="col-2 text-center">
                            <span><i class="fas fa-exclamation fa-3x"></i></span>
                        </div>
                        <div class="col-10">
                            <h3>Sorry, the area you selected is not within the
                                Urban Drool Tool service area.
                            </h3>
                        </div>
                    </div>
                    <br />
                    <div class="row section-search-not-found">
                        <div class="col-2 text-center">
                            <span><i class="fas fa-map-marker-alt fa-3x"></i></span>
                        </div>
                        <div class="col-10">
                            <h3>
                                Select an area within the highlighted service
                                boundary or enter an address in the search
                                bar above to view results.
                            </h3>
                        </div>
                    </div>
                </div>
                <div *ngIf="!activeSearchNotFound && !searchActive"
                    class="col-12 section-stats d-none d-lg-flex p-lg-4 flex-fill">
                    <div class="h-100 mt-auto mb-auto">
                        <h3>
                            Learn about how your neighborhood is conserving water and protecting our streams and beaches
                        </h3>
                        <h3 class="text-center">
                            <span><i class="fas fa-tint fa-2x"></i></span>
                        </h3>
                        <h3>
                            Click the exposed areas of the map to explore different neighborhood metrics or
                            enter your address in the search bar for your own detailed urban drool snapshot
                        </h3>
                    </div>
                </div>
                <div *ngIf="!activeSearchNotFound && searchActive"
                    class="col-12 section-stats d-none d-lg-flex p-lg-0 flex-fill">
                    <div class="h-100">
                        <div class="text-center p-lg-4">
                            <a routerLink="/{{selectedNeighborhoodID}}/fact-sheet" class="btn btn-drooltool text-center">View All The Facts!</a>
                            <p class="b3 watershed-intro mt-2 mb-0">This neighborhood drains to <span
                                    class="watershed-text">{{selectedNeighborhoodWatershed}}</span></p>
                        </div>
                        <div class="p-lg-4" style="background-color:#F2FBFD">
                            <h2 class="metric-heading">How much does your neighborhood drool?</h2>
                            <ng-container
                                *ngIf="selectedNeighborhoodMetrics && selectedNeighborhoodMetrics.TotalDrool">
                                <p class="b3"><span
                                        class="metric-value">{{selectedNeighborhoodMetrics.TotalDrool | number:'1.0-0':'en-US'}}</span><span
                                        class="metric-units"> gallons</span> of urban drool may have been created in
                                    {{months[selectedNeighborhoodMetrics.MetricMonth - 1]}}</p>
                                <p *ngIf="selectedNeighborhoodMetrics.TotalDrool > 20000" class="b4">This would be 
                                    enough
                                    urban drool to fill
                                    {{selectedNeighborhoodMetrics.TotalDrool / 20000 | number:'1.0-0':'en-US'}}
                                    swimming {{((selectedNeighborhoodMetrics.TotalDrool / 20000) | number:'1.0-0') == 1 ? "pool" : "pools"}}!</p>
                            </ng-container>
                            <ng-container
                                *ngIf="!selectedNeighborhoodMetrics || !selectedNeighborhoodMetrics.TotalDrool">
                                <p class="b3">Hmm, looks like we can't find anything for this metric at this time. Try
                                    selecting another neighborhood!</p>
                            </ng-container>
                        </div>
                        <div class="p-lg-4">
                            <h2 class="metric-heading">Does your neighborhood take action?</h2>
                            <ng-container
                                *ngIf="selectedNeighborhoodMetrics && selectedNeighborhoodMetrics.OverallParticipation">
                                <p class="b3"><span
                                        class="metric-value">{{selectedNeighborhoodMetrics.OverallParticipation === 0 ? "None" : selectedNeighborhoodMetrics.OverallParticipation}}</span><span
                                        class="metric-units"> of your neighbors</span> participate in Moulton Niguel's
                                    rebate
                                    programs.</p>
                            </ng-container>
                            <ng-container
                                *ngIf="!selectedNeighborhoodMetrics || !selectedNeighborhoodMetrics.OverallParticipation">
                                <p class="b3">Hmm, looks like we can't find anything for this metric at this time. Try
                                    selecting another neighborhood!</p>
                            </ng-container>
                            <p class="b4">Participation in rebate programs, like weather based smart sprinkler timers or
                                rotating
                                sprinkler nozzles, helps mitigate your home's creation of urban drool</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 d-none d-lg-block">
                    <div class="row">
                        <div class="col-6 section-rebates text-center">
                            <div class="row h-100">
                                <div class="col my-auto py-3">
                                    <a href="https://www.mnwd.com/rebates/" target="_blank">
                                        <p class="b3 mx-auto">
                                            View Rebates and learn more about water efficiency
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 section-water-bill">
                            <div class="row h-100">
                                <div class="col my-auto py-3">
                                    <a href="https://www.mnwd.com/payment/" target="_blank">
                                        <p class="b3 mx-auto">
                                            Access my <br /> water bill
                                        </p>
                                        <p class="b4 mx-auto">
                                            Via <br /> Moulton Niguel Water District
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-9 pl-0 pr-0">
        <div #mapDiv id={{mapID}} class="location-card" [style.min-height]="mapHeight" style="height:100%">
            <div id="buttonDiv" *ngIf="searchActive && areMetricsCollapsed" [class]="layerControlOpen ? 'shifted' : ''">
                <button class="btn btn-drooltool d-lg-none mb-1 w-100" [attr.aria-expanded]="!areMetricsCollapsed"
                    aria-expanded="false" aria-controls="collapsePanel" aria-label="Toggle navigation"
                    (click)="showMetrics($event)"><span>Drool Stats</span></button>
                <br class="d-lg-none"/>
                <button id="traceButton" (click)="displayTraceOrZoomToNeighborhood($event)"
                    class="btn btn-drooltool">{{traceActive ? "Zoom to my Neighborhood" : "Where does my water go?"}}</button>
            </div>
            <div class="mobile-stats-containing-div">
                <div *ngIf="searchActive" id="collapsePanel"
                    class="section-stats mt-2 collapse mobile-stats metrics-collapse d-lg-none"
                    [ngbCollapse]="areMetricsCollapsed" (click)="$event.stopPropagation();">
                    <div class="text-center p-2 p-md-4 metrics-panel-transparency">
                        <a routerLink="/{{selectedNeighborhoodID}}/fact-sheet" class="btn btn-drooltool text-center">View All The Facts!</a>
                        <p class="b3 watershed-intro mt-2 mb-0">This neighborhood drains to <span
                                class="watershed-text">{{selectedNeighborhoodWatershed}}</span></p>
                    </div>
                    <div class="p-2 p-md-4 metrics-panel-transparency-special">
                        <h2 class="metric-heading">How much does your neighborhood drool?</h2>
                        <ng-container
                            *ngIf="selectedNeighborhoodMetrics && selectedNeighborhoodMetrics.TotalDrool">
                            <p class="b3"><span
                                    class="metric-value">{{selectedNeighborhoodMetrics.TotalDrool | number:'1.0-0':'en-US'}}</span><span
                                    class="metric-units"> gallons</span> of urban drool were created in
                                {{months[selectedNeighborhoodMetrics.MetricMonth - 1]}}</p>
                            <p *ngIf="selectedNeighborhoodMetrics.TotalDrool > 20000" class="b4">This is enough
                                urban
                                drool to fill
                                {{(selectedNeighborhoodMetrics.TotalDrool / 20000) | number:'1.0-0':'en-US'}}
                                swimming
                                {{((selectedNeighborhoodMetrics.TotalDrool / 20000) | number:'1.0-0') == 1 ? "pool" : "pools"}}!</p>
                        </ng-container>
                        <ng-container
                            *ngIf="!selectedNeighborhoodMetrics || !selectedNeighborhoodMetrics.TotalDrool">
                            <p class="b3">Hmm, looks like we can't find anything for this metric at this time. Try
                                selecting another neighborhood!</p>
                        </ng-container>
                    </div>
                    <div class="p-2 p-md-4 metrics-panel-transparency">
                        <h2 class="metric-heading">Does your neighborhood take action?</h2>
                        <ng-container
                            *ngIf="selectedNeighborhoodMetrics && selectedNeighborhoodMetrics.OverallParticipation">
                            <p class="b3"><span
                                    class="metric-value">{{selectedNeighborhoodMetrics.OverallParticipation === 0 ? "None" : selectedNeighborhoodMetrics.OverallParticipation}}</span><span
                                    class="metric-units"> of your neighbors</span> participate in outdoor water use
                                rebate
                                programs.</p>
                        </ng-container>
                        <ng-container
                            *ngIf="!selectedNeighborhoodMetrics || !selectedNeighborhoodMetrics.OverallParticipation">
                            <p class="b3">Hmm, looks like we can't find anything for this metric at this time. Try
                                selecting another neighborhood!</p>
                        </ng-container>
                        <p class="b4">Participation in rebate programs, like weather based smart sprinkler timers or
                            rotating
                            sprinkler nozzles, helps mitigate your home's creation of urban drool</p>
                    </div>
                    <button *ngIf="searchActive" class="btn btn-drooltool metrics-button-panel-expanded"
                        [attr.aria-expanded]="!areMetricsCollapsed" aria-expanded="false" aria-controls="collapsePanel"
                        aria-label="Toggle navigation" (click)="showMetrics($event)"><span><a class="search-close"><i
                                    class="fas fa-close"></i></a></span></button>

                </div>
            </div>
        </div>
    </div>
    <div class="col-12 d-lg-none">
        <div class="row">
            <div class="col-6 section-rebates text-center">
                <div class="row h-100">
                    <div class="col my-auto py-3">
                        <a href="https://www.mnwd.com/rebates/" target="_blank">
                            <p class="b3 mx-auto">
                                View Rebates and learn more about water efficiency
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-6 section-water-bill">
                <div class="row h-100">
                    <div class="col my-auto py-3">
                        <a href="https://www.mnwd.com/payment/" target="_blank">
                            <p class="b3 mx-auto">
                                Access my <br /> water bill
                            </p>
                            <p class="b4 mx-auto">
                                Via <br /> Moulton Niguel Water District
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>