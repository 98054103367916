<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">Users</li>
  </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">Unassigned Users</h2>
    <p class="lead" *ngIf="users && unassignedUsers.length">
      This is a list of all users who are awaiting account configuration. Assign the user a role and billing account(s). If the account was created in error, set their Role to “Disabled” to remove them from this list.
    </p>
    <p class="lead" *ngIf="users && !unassignedUsers.length">
      Congratulations! All users have been assigned a role.
    </p>
</div>
<div class="container mt-sm-4" *ngIf="users && unassignedUsers.length">
  <div class="table-responsive">
    <ag-grid-angular #unassignedUsersGrid style="width: 100%; height: 300px;" class="ag-theme-balham" [rowData]="unassignedUsers"
      [columnDefs]="columnDefs" [pagination]="true" [paginationPageSize]="100">
    </ag-grid-angular>
  </div>
</div>
<div class="container">
    <div style="margin: 1rem 0 1rem 0;" class="float-right">
        <a class="btn btn-drooltool btn-sm mr-3" routerLink="/invite-user">Invite User</a>
        <a class="btn btn-secondary btn-sm" style="cursor: pointer;" (click)="exportToCsv()" placement="top" ngbTooltip="Download list of Users"><i
          class="fas fa-download"></i></a>
    </div>
    <h2 class="d-inline-block">All Users</h2>
    <p class="lead">
        A list of users is shown in the grid below.
    </p>
</div>
<div class="container mt-sm-4">
  <div class="table-responsive">
    <ag-grid-angular #usersGrid style="width: 100%; height: 800px;" class="ag-theme-balham" [rowData]="rowData"
      [columnDefs]="columnDefs" [pagination]="true" [paginationPageSize]="100">
    </ag-grid-angular>
  </div>
</div>