//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Role]

export enum RoleEnum {
  Admin = 1,
  Normal = 2,
  Unassigned = 3,
  Landowner = 4,
  Disabled = 5
}
